@import url('https://fonts.googleapis.com/css?family=Hind+Guntur:300,400,500,600,700');

@import "dropdown";

body {
	font-family: 'Hind Guntur', sans-serif;
}

.btn {
	border-radius: 0 !important;
}

.form-control {
	border: 1px solid #DDD !important;
	color: $default-text !important;
}

.btn, .btn:hover, .btn:focus {
	box-shadow: none;
	outline: none;
}

button.btn.btn-default:active {
	background-color: transparent !important;
}

.btn-primary {
	background-color: $primary !important;
	border: none !important;
	box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
	color: #FFF !important;

	&:hover, &:focus {
		border: none !important;
	}
}

.bg-primary {
	background-color: $primary !important;
}

.check-icon--primary {
	color: $primary;
}
