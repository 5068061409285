@import '~@angular/material/theming';
// Plus imports for other components in your app.
@import 'conf/conf';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// NOTE: From `2.0.0-beta.10`, you can now pass the typography via the mat-core() mixin:
@import '~@angular/material/theming';
$custom-typography: mat-typography-config(
    $font-family: $font-family
);
@include mat-core($custom-typography);

/* from http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5 */
$md-dokswhite: (
    50 : #fcf0e4,
    100 : #f7daba,
    200 : #f2c18d,
    300 : #eda85f,
    400 : #e9953c,
    500 : #e5821a,
    600 : #e27a17,
    700 : #de6f13,
    800 : #da650f,
    900 : #d35208,
    A100 : #fffdfc,
    A200 : #ffdac9,
    A400 : #ffb796,
    A700 : #ffa67d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$doks-app-primary: mat-palette($md-dokswhite);
$doks-app-accent:  mat-palette($md-dokswhite, A200, A100, A400);

// The warn palette is optional (defaults to red).
$doks-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$doks-app-theme: mat-light-theme($doks-app-primary, $doks-app-accent, $doks-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($doks-app-theme);

#doks_app .mat-card {
    background: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.12);
    border-radius: 0px;
}

#body .mat-button, #doks_app .mat-button, #doks_app .mat-expansion-panel {
    border-radius: 0px;
}

#body .mat-button[disabled] {
  color: #9b9b9b;
  background-color: #d8d8d8;
}

#body .mat-dialog-container .mat-button {
    margin: 0px;
}

.mat-dialog-content {
    margin: 0px -15px;
    padding: 0px 15px;
}

#body .mat-dialog-container {
    border-radius: 2px;
    padding: 15px;
    position: relative;
}

#body .mat-dialog-actions {
    min-height: unset;
    padding: unset;
}

#body .cdk-overlay-dark-backdrop {
    background: rgba(255, 255, 255, 0.7);
}

#body .mat-snack-bar-container {
    border-radius: 2px;
}

#body .mat-select-panel {
    border-radius: 0px;
}

#doks_app #top-mat-card .mat-form-field-underline, #doks_app #dokspagetopsub .mat-form-field-underline {
    background-color: $primary;
    height: 1px;
}

#doks_app .mat-progress-spinner circle, #doks_app .mat-spinner circle {
    stroke: inherit;
}

.grey .mat-progress-bar-buffer{
    background-color: #9a999b;
}

.listitem .mat-expansion-panel-body {
    background-color: #F8F8F8;
}

.listitem .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
    background: linear-gradient(rgb(255, 255, 255) 60%, rgb(248, 248, 248) 40%);
}

#doks_app th.mat-header-cell {
    padding-top: 7px;
}


#doks_app .mat-form-field-infix {
  border-top-width: 0px;
}

button:focus {outline:0;}
