/* You can add global styles to this file, and also import other style files */

.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  //background-color: rgba(0, 0, 0, 0.26);
  color: grey;
  cursor: default;
}

.mat-snack-bar-container.grey-snackbar {
  background-color: grey;
}

.mat-snack-bar-container.green-snackbar {
  background-color: green;
}

.mat-snack-bar-container.red-snackbar {
  background-color: red;
}

.float-right {
  float: right;
}

body .btn {
  padding: 0.444rem 1rem 0.244rem 1rem;
}
